<template>
  <div @click.alt="debug = !debug">
    <v-row class="mx-n3 mt-n4">
      <v-col
        v-for="(data, eventKind) in filteredEventReports"
        :key="`report-${eventKind}`"
        cols="12"
        md="auto"
        sm="6"
      >
        <quizzes-events-report-card
          :name="eventKind"
          :duration="data.total_duration"
          :count="data.occurrences"
          :icon="getIcon(eventKind)"
          :max="data.max_duration"
        />
      </v-col>
    </v-row>

    <v-card
      class="events-pairs-timeline"
      outlined
      flat
    >
      <EvalmeeQuizEventGenericTimeline
        :long-events="longEventsPrepared"
        :short-events="shortEventsPrepared"
        :line-events="lineEvents"
        :student-screenshots="studentScreenshots"
        class="ma-2"
      />
      <slot name="timelineAppend" />
    </v-card>
  </div>
</template>

<script>
import EvalmeeQuizEventGenericTimeline from "./evalmee_quiz_event_generic_timeline.vue"
import stringHelper from "../../helpers/string_helpers"
import QuizzesEventsReportCard from "./events-timeline/eventReportCard.vue"

export default {
  name: "EvalmeeQuizEventsPairsTimeline",
  components: {
    QuizzesEventsReportCard,
    EvalmeeQuizEventGenericTimeline,
  },
  props: {
    eventsPairs: {
      type: Array,
      required: true,
    },
    events: {
      type: Array,
      required: true,
    },
    exercises: {
      type: Array,
      required: true,
    },
    eventsReports: {
      type: Object,
      required: true,
    },
    studentScreenshots: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    debug: false,
  }),
  computed: {
    eventsInReport() {
      return [
        "fullscreen_loss",
        "focus_loss",
        "window_hidden",
        "mouse_leave",
        ...(this.debug ? ["idle"] : []),
        "offline",
        ...(this.debug ? ["exam_sessions"] : []),
        ...(this.debug ? ["exam_left"] : []),
      ]
    },

    longEventsPrepared() {

      const longEventKinds = [
        "fullscreen_loss",
        "focus_loss",
        "window_hidden",
        "mouse_leave",
        ...(this.debug ? ["screen_share_loss"] : []),
        ...(this.debug ? ["device_sleep"] : []),
        ...(this.debug ? ["idle"] : []),
        "offline",
        ...(this.debug ? ["exam_sessions"] : []),
        ...(this.debug ? ["exam_left"] : []),
      ]
      const eventKinds = longEventKinds.filter(kind => this.eventsPairs.some(eventPair => eventPair.kind === kind))

      return eventKinds.map(kind => {
        const eventsOfThisKind = this.eventsPairs.filter(eventPair => eventPair.kind === kind)
        return {
          kind: kind,
          color: this.getColor(kind),
          icon: this.getIcon(kind),
          name: this.$t(`live.studentEvents.kind.${this.toCamelCase(kind)}.label`),
          events: eventsOfThisKind.map(event => ({
            start: new Date(event.start_date).getTime(),
            end: new Date(event.end_date).getTime(),
            exercises: event.exercise_ids?.map(id => ({
              id: id,
              name: this.exercises?.find(exercise => exercise.id === id)?.displayName,
            })),
            ...event,
          })),
        }
      })
    },
    shortEventsPrepared() {

      const shortEventKinds = [
        "answers_last_update",
        ...(this.debug ? ["text_selection"] : []),
        "copy",
        "cut",
        "paste",
        "bulk_insert",
        "fast_insert",
        "resize_browser",
        ...(this.debug ? ["enter_exam"] : []),
        ...(this.debug ? ["page_load"] : []),
        ...(this.debug ? ["dev_tool_open"] : []),
        ...(this.debug ? ["console_log"] : []),
      ]

      const eventKinds = shortEventKinds.filter(kind => this.events.some(event => event.kind === kind))

      return eventKinds.map(kind => {
        const eventsOfThisKind = this.events.filter(event => event.kind === kind)
        return {
          kind: kind,
          color: this.getColor(kind),
          icon: this.getIcon(kind),
          name: this.$t(`live.studentEvents.kind.${this.toCamelCase(kind)}.label`),
          events: eventsOfThisKind.map(event => ({
            date: new Date(event.happened_at).getTime(),
            exercises: [{
              id: event.quizzes_exercise_id,
              name: this.exercises?.find(exercise => exercise.id === event.quizzes_exercise_id)?.displayName,
            }].filter(exercise => exercise.id),
            ...event,
          })),
        }
      })
    },
    lineEvents() {
      return this.events
        .filter(event => event.kind === "enter_exercise")
        .map(event => ({
          color: this.getColor(event.kind),
          date: new Date(event.happened_at).getTime(),
          exerciseName: this.exercises
            ?.find(exercise => exercise.id === event.quizzes_exercise_id)
            ?.displayName,
          ...event,
        })
        )
    },

    filteredEventReports() {
      return Object.keys(this.eventsReports || {}).reduce((acc, key) => {
        if (this.eventsInReport.includes(key) && this.eventsReports[key].occurrences > 0) {
          acc[key] = this.eventsReports[key]
        }
        return acc
      }, {})
    },
  },
  methods: {
    getColor(kind) {
      const colors = {
        fullscreen_loss: "red",
        mouse_leave: "deep-orange",
        idle: "indigo",
        focus_loss: "deep-purple",
        window_hidden: "deep-purple",
        offline: "amber",
        online: "yellow",
        exam_sessions: "light-green",
        text_selection: "green",
        copy: "pink",
        paste: "cyan",
        cut: "brown",
        page_load: "grey",
        bulk_insert: "purple",
        fast_insert: "lime",
        resize_browser: "orange",
        enter_exercise: "lightblue",
        exam_left: "blue",
        dev_tool_open: "teal",
        enter_exam: "blue",
        console_log: "teal",
        answers_last_update: "blue",
        screen_share_loss: "brown",
        device_sleep: "brown",
      }
      return colors[kind]
    },

    getIcon(kind) {
      const icons = {
        fullscreen_loss: "mdi-fullscreen-exit",
        mouse_leave: "mdi-cursor-default-outline",
        idle: "mdi-sleep",
        focus_loss: "mdi-application-export",
        window_hidden: "mdi-tab-unselected",
        offline: "mdi-wifi-off",
        online: "mdi-wifi",
        exam_sessions: "mdi-ray-start-end",
        text_selection: "mdi-cursor-text",
        copy: "mdi-content-copy",
        paste: "mdi-content-paste",
        cut: "mdi-content-cut",
        page_load: "mdi-web",
        bulk_insert: "mdi-robot-outline",
        fast_insert: "mdi-keyboard-off-outline",
        resize_browser: "mdi-resize",
        enter_exercise: "mdi-book-open-page-variant",
        exam_left: "mdi-exit-run",
        dev_tool_open: "mdi-console",
        enter_exam: "mdi-ray-start-arrow",
        console_log: "mdi-console-line",
        answers_last_update: "mdi-text-box-edit-outline",
        screen_share_loss: "mdi-television-off",
        device_sleep: "mdi-power-sleep",
      }
      return icons[kind]
    },
    toCamelCase(str) {
      return stringHelper.toCamelCase(str)
    },
  },
}
</script>

<style scoped>
.events-pairs-timeline {
  width: 100%;
}
</style>
