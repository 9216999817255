<template>
  <v-card
    flat
    tile
    v-click-outside="() => focused = false"
  >
    <v-expand-transition>
      <div v-show="!inEmptyState">
        <div
          class="pa-2"
        >
          <div
            class="d-flex align-center"
          >
            <eva-big-icon
              icon="mdi-eye-off-outline"
              :size="20"
              color="blue"
            />
            <span
              class="ml-2"
              :class="{
                'subtitle-2': !inEmptyState,
                'body-2': inEmptyState,
                'grey--text': inEmptyState,
              }"
            >
              {{ inEmptyState ?
                $t('live.correction.privateFeedback.placeholder') :
                $t('live.correction.privateFeedback.label') }}
            </span>
          </div>
        </div>
        <v-divider />
      </div>
    </v-expand-transition>
    <div @click="setFocus">
      <div class="d-flex">
        <div
          class="pa-2 mt-1"
          v-if="inEmptyState"
        >
          <eva-big-icon
            icon="mdi-eye-off-outline"
            :size="20"
            color="blue"
          />
        </div>
        <div
          class="flex-grow-1 mt-1"
        >
          <v-expand-transition>
            <text-editor
              v-model="comment"
              :placeholder="$t('live.correction.privateFeedback.placeholder')"
              ref="textEditor"
              :floating-menu="false"
              :style="{
                'min-height': inEmptyState ? '10px !important' : 'none',
                'max-height': inEmptyState ? '10px !important' : 'none',
              }"
              :class="{
                'mx-n7': inEmptyState,
                'my-n2': inEmptyState,
                'mx-2': !inEmptyState,

              }"
              min-height="0px"
            />
          </v-expand-transition>
        </div>
      </div>
      <div v-show="!inEmptyState">
        <v-card-actions class="px-0">
          <v-spacer />
          <eva-button
            @click="save"
            dense
            primary
            v-if="comment !== content"
            class="mr-2"
          >
            {{ $t("evalmee.home.teacher.openQuestionGrader.saveComment") }}
          </eva-button>
        </v-card-actions>
      </div>
    </div>
  </v-card>
</template>

<script>
import getFeatureMixin from "@/components/shared/get_feature_mixin"
import TextEditor from "@/components/shared/text_editor/text_editor.vue"
import EvaBigIcon from "@/components/shared/eva-big-icon.vue"
import stringHelpers from "@/helpers/string_helpers"

export default {
  name: "PrivateCommentEditor",
  components: { EvaBigIcon, TextEditor },
  mixins: [getFeatureMixin],
  props: {
    content: { type: Object, required: true },
  },
  data: () => ({
    comment: "",
    focused: false,
  }),
  computed: {
    inEmptyState() {
      if (this.focused) return false
      return stringHelpers.isEmptyHtml(this.comment || "")
    },
  },
  methods: {
    setFocus() {
      this.focused = true
      this.$nextTick(() => {
        this.$refs.textEditor.focus()
      })
    },
    save() {
      this.$emit("save", this.comment)
    },
  },
  watch: {
    "content": {
      handler(val) {
        this.comment = val
      },
      immediate: true,
    },
  },
}
</script>

<style scoped>
</style>
