import { mapGetters } from "vuex"

export default {
  computed: {
    ...mapGetters([
      "quizInstanceContentByQuizId",
      "quizInstanceByQuizId2",
      "currentUser",
      "instancesLoading",
      "paperSheetContantByQuizIdAndUserId",
    ]),

    quizInstance() { return this.quizInstanceByQuizId2(this.quiz?.id, this.userId) },

    quizSheet() { return this.paperSheetContantByQuizIdAndUserId(this.quiz?.id, this.userId) },

    /**
     * @return {Array}
     */
    exercises() {
      return this.quizInstanceExercises || this.quizSheetExercises || []
    },

    /**
     * @return {Array, undefined}
     */
    quizSheetExercises() {
      if(!this.quizSheet?.children) return
      return this.quizSheet?.children[0]?.children
    },

    /**
     * @return {Array, undefined}
     */
    quizInstanceExercises() {
      if(!this.quizInstanceContentByQuizId(this.quiz?.id, this.userId)?.children) return

      return this.quizInstanceContentByQuizId(this.quiz?.id, this.userId)?.children?.flatMap( section => section?.children)
    },

    quizInstanceSections() {
      if(!this.quizInstanceContentByQuizId(this.quiz?.id, this.userId)?.children) return

      return this.quizInstanceContentByQuizId(this.quiz?.id, this.userId)?.children
    },
  },
}
