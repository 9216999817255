<template>
  <div>
    <v-lazy
      v-for="(question, index) in questions"
      :key="question.id"
      :options="{threshold: .5}"
      class="mb-12"
      min-height="200"
      transition="fade-transition"
    >
      <question-normal
        :correction-mode="true"
        :index="index"
        :manual-correction-mode="teacherMode"
        :question="parsedQuestion(question)"
        :quiz="quiz"
        :show-score="quiz.show_scores || currentUser.teacher"
        :user-id="userId"
      >
        <template slot="grader">
          <open-question-grader
            :answer="answerByQuestionAndUserId(question.id, userId)"
            :current-user="currentUser"
            :label="$t('live.correction.manual')"
            :quiz="quiz"
          />
        </template>

        <template #grader="{answer = answerByQuestionAndUserId(question.id, userId), question = parsedQuestion(question)}">
          <open-question-grader
            v-if="answer"
            :answer="answer"
            :current-user="currentUser"
            :label="$t('live.correction.manual')"
            :question="question"
            :quiz="quiz"
            class="mb-2"
          />
        </template>
        <template #feedbackEditor="{answer = answerByQuestionAndUserId(question.id, userId), question = parsedQuestion(question), questionComment, hasQuestionComment}">
          <v-card
            flat
            outlined
          >
            <question-correction-comment
              v-if="hasQuestionComment"
              :content="questionComment"
            />
            <v-divider v-if="hasQuestionComment" />
            <answer-feedback-editor
              v-if="answer"
              :answer="answer"
              :question="question"
              :quiz="quiz"
            />
          </v-card>
        </template>
      </question-normal>
    </v-lazy>
  </div>
</template>
<script>
import OpenQuestionGrader from "../../components/questions/open_question_grader.vue"
import QuestionNormal from "./../../components/questions/question_normal.vue"
import AnswerFeedbackEditor from "@/components/questions/answerFeedbackEditor.vue"
import QuestionCorrectionComment from "@/components/questions/question_correction_comment.vue"

export default {
  name: "LegacyCorrection",
  components: { QuestionCorrectionComment, AnswerFeedbackEditor, OpenQuestionGrader, QuestionNormal },
  props: {
    questions: {},
    quiz: {},
    teacherMode: {},
    userId: {},
    answerByQuestionAndUserId: Function,
    currentUser: {},
  },
  methods: {
    parsedQuestion(question) {
      return {
        id: question.id,
        attributes: question,
        children: this.parsedChoice(question.choices),
        subtitle: () => "",
        subtitleInstructions: () => "",
        isAnswerable: question.type !== "text",
        allowMultipleChoices: question.allow_multiple_choices,
      }
    },

    parsedChoice(choice) {
      return {
        id: choice.id,
        attributes: choice,
      }
    },
  },
}
</script>
