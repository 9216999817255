<template>
  <v-data-table
    dense
    sort-desc
    sort-by="happened_at"
    :headers="headers"
    :items="parsedEvents"
    :search="search"
    show-expand
  >
    <template #top>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        solo
        flat
        filled
        class="mx-4"
        dense
      />
    </template>
    <template #item.certified="{item}">
      <v-icon
        :color="item.certified ? 'green' : 'red'"
        v-html="item.certified ? 'mdi-check' : 'mdi-close'"
        small
      />
    </template>
    <template #item.details="{ item }">
      <div
        v-for="(value, key) in item.details"
        :key="key"
      >
        <evalmee-chip
          v-html="key"
          small
          label
          color="grey"
        />
        : {{ value && value.length > 10 ? value.slice(0, 10) + "..." : value }}
      </div>
    </template>

    <template #item.happened_at="{ item }">
      {{ $moment(item.happened_at).format("llll:ss:SS") }}
    </template>

    <template #item.time_diff="{ item }">
      {{ $moment.duration(item.time_diff).format("mm[min] ss[s] SSS[ms]") }}
    </template>

    <template #expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <div
          v-for="(value, key) in item.details"
          :key="key"
        >
          <evalmee-chip
            v-html="key"
            small
            label
            color="grey"
          />
          :
          <pre>
            {{ value }}
          </pre>
        </div>
      </td>
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from "vuex"
import EvalmeeChip from "../../components/shared/evalmee_chip.vue"

export default {
  name: "EvalmeeQuizEvents",
  components: { EvalmeeChip },
  props: {
    scoreId: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    headers: [
      { text: "", value: "certified" },
      { text: "index", value: "frontend_index" },
      { text: "Type", value: "kind" },
      { text: "Date", value: "happened_at" },
      { text: "time_diff", value: "time_diff" },
      { text: "Exercise", value: "quizzes_exercise_id" },
      { text: "Details", value: "details" },
      { text: "Tab", value: "browserTabToken" },
    ],
    search: "",
  }),
  computed: {
    ...mapGetters(["studentEvents"]),
    events() {
      return this.studentEvents(this.scoreId).map((event) => {
        return {
          ...event,
          browserTabToken: event.details?.browser_tab_token,
        }
      })
    },
    parsedEvents() {
      // sort events by happened_at
      const sortedEvents = this.events.sort((a, b) => {
        return new Date(a.happened_at) - new Date(b.happened_at)
      })

      //return this.event and add an attribute "time_diff" with the time difference between the event and the previous one

      return sortedEvents.map((event, index, array) => {
        event.time_diff =
          index === 0
            ? 0
            : new Date(event.happened_at) -
              new Date(array[index - 1].happened_at)
        return event
      })
    },
  },
}
</script>

<style scoped></style>
