<template>
  <div>
    <v-card
      v-for="(data, eventKind) in score.events_report.report"
      :key="eventKind"
      outlined
      class="mb-2"
      flat
    >
      <v-data-table
        :headers="headers"
        :items="score.events_report.events_pairs.filter(e => e.kind === eventKind)"
        dense
        hide-default-footer
        :items-per-page="1000000"
      >
        <template #top>
          <div class="py-2 px-4">
            <b>{{ eventKind }}</b>
            <span>
              {{ $moment.duration(data.total_duration_timestamp ).format("mm[min] ss[s] SSS[ms]") }}
            </span>
            <span class="grey--text">
              ({{ data.occurrences }})
            </span>
          </div>
        </template>

        <template #item.start_date="{ item }">
          {{ $moment(item.start_date).format("llll:ss:SS") }}
        </template>

        <template #item.end_date="{ item }">
          {{ $moment(item.end_date).format("llll:ss:SS") }}
        </template>

        <template #item.duration="{ item }">
          {{ $moment.duration(item.duration_timestamp ).format("mm[min] ss[s] SSS[ms]") }}
        </template>

        <template #item.exercise_ids="{ item }">
          {{ item.exercise_ids.join(', ') }}
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "EvalmeeQuizEventsReport",
  props: {
    score: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        { text: "Start Date (ms)", value: "start_date" },
        { text: "End Date (ms)", value: "end_date" },
        { text: "Duration (ms)", value: "duration" },
        { text: "Exercises", value: "exercise_ids" },
      ],
    }
  },
  computed: {
    ...mapGetters(["studentEvents"]),
    events() {
      return this.studentEvents(this.score.id)
    },
  },
}
</script>
