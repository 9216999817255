<template>
  <v-alert
      class="mb-5 mt-5"
      v-if="isDisplayed"
      type="info"
      :icon="false"
      text
  >
    {{ $t("live.correction.unauthorizedCorrectionAccess") }}
  </v-alert>
</template>

<script>

export default {
  name: "cheatScoreAlert",
  props: {
    teacherMode: {},
    quiz: {},
  },
  computed: {
    isDisplayed () {
      return !this.teacherMode && this.quiz && !this.quiz.whole_correction_visible
    },
  },
}
</script>
