export function useScroll() {

  const to = (ref, parentRef)  => {
    const el = ref
    const parentEl = parentRef

    if (el && parentEl) {
      const elRect = el.getBoundingClientRect()
      const parentRect = parentEl.getBoundingClientRect()

      parentEl.scrollBy({
        top: elRect.top - parentRect.top,
        left: elRect.left - parentRect.left,
        behavior: "smooth",
      })
    }
  }

  return {
    to,
  }
}
