<template>
  <v-dialog
    v-model="scanModal"
    max-width="800"
  >
    <template v-slot:activator="{ on }">
      <v-alert
        dense
        text
        :color="color"
        dark
        :type="type"
      >
        <v-row align="center" class="mb-0">
          <v-col class="grow">
            Vous pouvez consulter votre copie corrigée
          </v-col>
          <v-col class="shrink">
            <v-btn
              small
              outlined
              :color="color || 'primary'"
              v-on="on"
              @click="fetchScanSheet(score)"
            >
              Voir copie
            </v-btn>
          </v-col>
        </v-row>
      </v-alert>
    </template>

    <v-card>

      <v-card-text>
        <div class="pt-5">
          <v-alert
            v-if="!hideSubjectLink"
            type="info"
            dense
            text
            min-width="100%"
          >
            <v-row align="center" class="mb-0">
              <v-col class="grow">
                <span v-if="quiz.random_questions">
                  Attention l'ordre des questions et des réponses n'est pas celui de la correction.
                </span>
                <span v-else>
                  Attention l'ordre des réponses n'est pas celui de la correction.
                </span>
                <span>
                  Vous pouvez télécharger le sujet correspondant pour le comparer à la grille de réponse.
                </span>
              </v-col>
              <v-col class="shrink">
                <v-btn
                  small
                  outlined
                  color="primary"
                  :href="subjectUrl"
                  target="_blank"
                >
                  Télécharger le sujet
                </v-btn>
              </v-col>
            </v-row>
          </v-alert>

          <v-alert
            type="info"
            dense
            text
            min-width="100%"
            v-if="scan && scan.edited"
          >
            Les réponses détectées automatiquement ont été modifiées manuellement. Les croix rouges présentes ci-dessous ne représentent pas les réponses prises en compte.
          </v-alert>
        </div>

        <img :src="scan && scan.marked_file && scan.marked_file.url"
             alt=""
             class="scan">
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn
          color="primary"
          text
          @click="scanModal = false"
        >
          Fermer
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex"

export default {
  name: "scanModale",
  props: {
    score: Object,
    quiz: Object,
    color: String,
    hideSubjectLink: { type: Boolean, default: false },
  },
  data: function () {
    return {
      scanModal: false,
    }
  },
  methods: {
    ...mapActions(["fetchScanSheet", "fetchScore"]),
  },
  computed: {
    ...mapGetters(["getScanSheetById"]),
    scan() {
      return this.getScanSheetById(
        this.score &&
          this.score.scanned_paper_quiz_sheet_id
      )
    },
    type() { return this.color ? null : "info"},
    subjectUrl() {
      const { scan } = this
      return scan && scan.subject_file_url
    },
  },
  watch: {
    score: {
      handler() {
        // reload score to get scan
        if(!this.score?.id) return
        if(this.score.scanned_paper_quiz_sheet_id) return
        this.fetchScore({ scoreId: this.score.id })
      },
      immediate: true,
    },
  },
}
</script>

<style scoped>
  .scan{
    width: 100%;
  }
</style>
