<template>
  <div>
    <v-card
      class="mb-4"
      v-if="this.teacherMode"
      outlined
      flat
    >
      <div class="d-flex">
        <div style="width: 100%">
          <evalmee-chip
            color="blue"
            small
            label
            icon="mdi-creation-outline"
            class="ma-2"
            v-if="['ai_generated', 'ai_student_generated'].includes(score.comment_source) && !score.manually_validated_comment"
          >
            {{ $t('live.correction.generalFeedback.aiGenerated') }}
          </evalmee-chip>
          <v-textarea
            :placeholder="
              $t('live.correction.generalFeedback.placeholder')"
            :label="$t('live.correction.generalFeedback.label')"
            v-model="public_comment"
            flat
            auto-grow
            rows="1"
            class="pa-4 no-line ma-0"
            hide-details
            @focus="isFocused = true"
            @blur="isFocused = false"
          />
        </div>
        <div class="pa-2 mt-2">
          <div class="d-flex align-center">
            <evalmee-chip
              small
              class="mr-2"
              label
              color="red"
            >
              Beta
            </evalmee-chip>
            <eva-button
              dense
              v-tooltip="$t('evalmee.home.teacher.openQuestionGrader.addComment.generateFeedback.buttonTooltip')"
              class="pa-2"
              @click="generateFeedback"
              :icon-left="canSave || hasComment ? null : 'mdi-creation-outline'"
              :icon="!(canSave || hasComment) ? null : 'mdi-creation-outline'"
              :label="canSave || hasComment ? null : $t('evalmee.home.teacher.openQuestionGrader.addComment.generateFeedback.label')"
              :loading="generatingFeedback"
            />
          </div>
        </div>
      </div>

      <v-card-actions
        class="pt-0"
        v-if="isFocused || canSave"
      >
        <v-spacer />
        <eva-button
          dense
          color="primary"
          :disabled="!canSave"
          @click="saveScore"
        >
          {{ $t('live.correction.generalFeedback.save') }}
        </eva-button>
      </v-card-actions>
    </v-card>
    <div v-else>
      <v-card
        outlined
        flat
        v-if="score.public_comment"
        dense
        text
        class="pb-2"
      >
        <div class="d-flex pa-2">
          <eva-big-icon
            icon="mdi-account"
            size="20"
            color="blue"
          />
          <span class="subtitle-2 ml-2">{{ $t('live.correction.generalFeedback.label') }}</span>
          <v-spacer />
          <evalmee-chip
            color="blue"
            small
            label
            icon="mdi-creation-outline"
            v-if="['ai_generated', 'ai_student_generated'].includes(score.comment_source) && !score.manually_validated_comment"
          >
            {{ $t('live.correction.generalFeedback.aiGenerated') }}
          </evalmee-chip>
        </div>
        <v-divider />
        <v-card-text>
          <katex :expr="score.public_comment" />
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex"
import Katex from "../katex-md.vue"
import axios from "axios"
import EvalmeeChip from "@/components/shared/evalmee_chip.vue"
import EvaBigIcon from "@/components/shared/eva-big-icon.vue"

export default {
  name: "StudentGlobalComment",
  components: { EvaBigIcon, EvalmeeChip, Katex },
  props: {
    score: { type: Object, required: true },
    quiz: { type: Object, required: true },
    teacherMode: { type: Boolean, default: false },
  },
  data: () => ({
    public_comment: "",
    isFocused: false,
    generatingFeedback: false,
  }),
  computed: {
    canSave() {
      return this.public_comment !== this.score.public_comment
    },
    hasComment() {
      return !!this.score.public_comment
    },
  },
  methods: {
    ...mapActions([
      "updateScoreBySummary",
      "newAppNotification",
    ]),
    saveScore() {
      this.updateScoreBySummary({ ...this.score, public_comment: this.public_comment, manually_validated_comment: true })
        .then( () => this.newAppNotification({
          message: this.$t("quiz.edit.toast.successMessage"),
          type: "success",
        })
        ).catch((error) =>
          this.newAppNotification({
            message: this.$t("quiz.edit.toast.errorMessage", { error }),
            type: "error",
          })
        )
    },
    generateFeedback() {
      return axios
        .post(this.score.links.generate_feedback)
        .then(() => this.generatingFeedback = true)
    },
  },
  mounted() {
    this.public_comment = this.score.public_comment
  },
  watch: {
    "score.public_comment": {
      handler(val, oldVal) {
        if (oldVal === this.public_comment || oldVal === null) {
          this.public_comment = val
          // This is a dirty hack to mark the comment ia generated with updated via websocket
          this.generatingFeedback = false
        }
      },
    },
  },
}
</script>
