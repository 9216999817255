<template>
  <div class="d-flex">
    <div>
      <div
        v-for="(event, index) in [...shortEvents, ...longEvents]"
        :key="'event-icon-' + index"
        class="event-icon pr-2"
        v-tooltip="event.name"
      >
        <v-icon
          v-html="event.icon"
          small
        />
      </div>
    </div>

    <div class="timeline-container">
      <v-card
        flat
        text
        outlined
        class="timeline"
      >
        <v-btn
          small
          @click="moveToLeft"
          class="move-button pa-0"
          style="left: 8px;"
          v-if="showLeftButton"
        >
          <v-icon
            small
            v-html="'mdi-arrow-left'"
          />
        </v-btn>
        <v-btn
          small
          @click="moveToRight"
          class="move-button right pa-0"
          style="right: 8px;"
          v-if="showRightButton"
        >
          <v-icon
            small
            v-html="'mdi-arrow-right'"
          />
        </v-btn>
        <div
          v-for="(event, index) in filteredLineEvents"
          :key="'line-' + index"
        >
          <div
            class="line smooth-move"
            :style="{
              left: ((event.date - minTimestamp) / duration * 100) + '%',
            }"
          />
          <v-menu
            open-on-hover
            offset-y
            nudge-top="16"
            top
            max-width="250"
          >
            <template #activator="{on ,attrs}">
              <div
                v-bind="attrs"
                v-on="on"
                class="hover-overlay"
                :style="{
                  left: ((event.date - minTimestamp) / duration * 100) + '%',
                  width: index === filteredLineEvents.length - 1 ? 'calc(100% - ' + ((event.date - minTimestamp) / duration * 100) + '%)' : ((filteredLineEvents[index + 1].date - event.date) / duration * 100) + '%',
                }"
                :class="{
                  'primaryLight2': hoveredExerciseId === event.quizzes_exercise_id && hoveredExerciseId !== null,
                }"
                @mouseover="hoveredExerciseId = event.quizzes_exercise_id"
                @mouseleave="hoveredExerciseId = null"
              />
            </template>

            <v-card>
              <v-card-text>
                <katex-md
                  :expr="event.exerciseName"
                  remove-all-html
                  style="max-height: 100px; overflow: auto;"
                />
                <template v-if="false">
                  <!--used for debug only-->
                  {{ timeHelpers.formatTimeDateWithSeconds(event.happened_at) }}
                </template>
              </v-card-text>
            </v-card>
          </v-menu>
        </div>
        <div
          v-for="(event, index) in filteredShortEvents"
          :key="'short-' + index"
          class="shortEventGroup mt-1"
        >
          <v-menu
            open-on-hover
            v-for="(timeEvent, subIndex) in event.events"
            :key="event.kind + index + subIndex"
            right
            :top="timeEvent.kind === 'answers_last_update'"
            offset-overflow
            offset-x
            offset-y
            max-width="250"
            :close-on-content-click="false"
          >
            <template #activator="{on ,attrs}">
              <div
                v-bind="attrs"
                v-on="on"
                class="smooth-move short-event-marker"
                :class="{
                  'big-short-event-marker': similarTimeEventsByGroup(timeEvent).length > 0 && timeEvent.kind !== 'answers_last_update',
                  'dot': timeEvent.kind !== 'answers_last_update',
                  'short-event-marker-line': timeEvent.kind === 'answers_last_update',
                }"
                :style="{
                  left: ((timeEvent.date - minTimestamp) / duration * 100) + '%',
                }"
              >
                <div
                  class="short-event-marker-background"
                  :class="event.color"
                >
                  <span v-if="showEventTabId && timeEvent.kind !== 'answers_last_update'">
                    {{ tabIdFor(timeEvent?.details?.browser_tab_token) }}
                  </span>
                </div>
              </div>
            </template>

            <quizzes-events-timeline-event-detail
              :event="timeEvent"
              :similar-events-by-group="similarTimeEventsByGroup(timeEvent)"
              :tab-id="tabIdFor(timeEvent?.details?.browser_tab_token)"
            />
          </v-menu>
        </div>
        <div
          v-for="(event, index) in filteredLongEvents"
          :key="'long-' + index"
          class="eventGroup mt-1"
        >
          <v-menu
            open-on-hover
            v-for="(timeEvent, subIndex) in event.events"
            :key="event.kind + index + subIndex"
            bottom
            offset-y
            max-width="250"
            :close-on-content-click="false"
          >
            <template #activator="{on ,attrs}">
              <div
                v-bind="attrs"
                v-on="on"
                class="rectangle smooth-move"
                :class="event.color"
                :style="{
                  left: ((timeEvent.start - minTimestamp) / duration * 100) + '%',
                  width: ((timeEvent.end - timeEvent.start) / duration * 100) + '%',
                }"
              >
                <div
                  class="rectangle-background lighten-5"
                  :class="event.color"
                />
              </div>
            </template>

            <quizzes-events-timeline-event-detail
              :event="timeEvent"
              :similar-events-by-group="similarTimeEventsByGroup(timeEvent)"
            />
          </v-menu>
        </div>

        <div
          id="screenshots-container"
          class="blue-grey lighten-5"
        >
          <div
            v-for="(screenshot, index) in studentScreenshots"
            :key="'screenshot-' + index"
            class="screenshot-marker"
            :style="{
              left: ((new Date(screenshot.happened_at).getTime() - minTimestamp) / duration * 100) + '%',
            }"
          >
            <v-img
              :src="screenshot.url"
              max-width="40"
              max-height="30"
              min-height="30"
              class="screenshot-thumbnail"
              @click="openScreenshotDialog(screenshot)"
            />
          </div>
        </div>
      </v-card>
      <v-range-slider
        v-model="timeWindow"
        :min="minTimestampDefault"
        :max="maxTimestampDefault"
        hide-details
      />
      <v-dialog
        v-model="showScreenshotDialog"
        max-width="90%"
      >
        <v-img
          :src="selectedScreenshot.url"
          v-if="selectedScreenshot"
          @click="showScreenshotDialog = false"
        />
      </v-dialog>
    </div>
  </div>
</template>

<script>
import QuizzesEventsTimelineEventDetail from "./events-timeline/eventDetail.vue"
import KatexMd from "../../components/katex-md.vue"
import timeHelpers from "../../helpers/time_helpers"

const windowBuffer = 0.5
export default {
  name: "EvalmeeQuizEventGenericTimeline",
  components: { KatexMd, QuizzesEventsTimelineEventDetail },
  props: {
    longEvents: {
      type: Array,
      required: true,
    },
    shortEvents: {
      type: Array,
      required: true,
    },
    lineEvents: {
      type: Array,
      required: true,
    },
    studentScreenshots: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    timeWindow: [0,0],
    hoveredExerciseId: null,
    showScreenshotDialog: false,
    selectedScreenshot: null,
  }),
  computed: {
    timeHelpers() {
      return timeHelpers
    },
    minTimestampDefault() {
      const timestamps = [
        ...this.longEvents.map(event => event.events.map(e => e.start)).flat(),
        ...this.shortEvents.map(event => event.events.map(e => e.date)).flat(),
        ...this.lineEvents.map(event => event.date),
      ]

      // Math.min(...[]) returns Infinity,
      // so we need to handle the case where there are no timestamps
      return timestamps.length > 0 ? Math.min(...timestamps) : 0
    },
    maxTimestampDefault() {
      const timestamps = [
        ...this.longEvents.map(event => event.events.map(e => e.end)).flat(),
        ...this.shortEvents.map(event => event.events.map(e => e.date)).flat(),
        ...this.lineEvents.map(event => event.date),
      ]
      return Math.max(...timestamps, 0)
    },
    duration() {
      return this.maxTimestamp - this.minTimestamp
    },
    sortedLineEvents() {
      return [...this.lineEvents].sort((a, b) => a.date - b.date)
    },
    filteredLongEvents() {
      return this.longEvents.map(eventsGroup  => {
        return {
          ...eventsGroup,
          events: eventsGroup.events.filter(event => {
            const start = event.start
            const end = event.end

            const minTimestamp = this.minTimestamp - this.duration * windowBuffer
            const maxTimestamp = this.maxTimestamp + this.duration * windowBuffer
            // return if event is in the time window even partially
            return (start >= minTimestamp && start <= maxTimestamp) ||
              (end >= minTimestamp && end <= maxTimestamp)
          }),
        }
      })
    },
    filteredShortEvents() {
      return this.shortEvents.map(eventsGroup  => {
        return {
          ...eventsGroup,
          events: eventsGroup.events.filter(event => {
            const date = event.date

            const minTimestamp = this.minTimestamp - this.duration * windowBuffer
            const maxTimestamp = this.maxTimestamp + this.duration * windowBuffer
            // return if event is in the time window even partially
            return date >= minTimestamp && date <= maxTimestamp
          }),
        }
      })
    },
    filteredLineEvents() {
      return this.sortedLineEvents.filter(event => {
        const date = event.date

        const minTimestamp = this.minTimestamp - this.duration * windowBuffer
        const maxTimestamp = this.maxTimestamp + this.duration * windowBuffer
        // return if event is in the time window even partially
        return date >= minTimestamp && date <= maxTimestamp
      })
    },
    minTimestamp() {
      return this.timeWindow[0]
    },
    maxTimestamp() {
      return this.timeWindow[1]
    },
    moveStep() {
      return this.timeWindowDuration / 5
    },
    timeWindowDuration() {
      return this.maxTimestamp - this.minTimestamp
    },
    showLeftButton() {
      return this.minTimestamp > this.minTimestampDefault
    },
    showRightButton() {
      return this.maxTimestamp < this.maxTimestampDefault
    },
    showEventTabId() {
      // return true if there is at least 2 different tab token  in shortEvents
      return this.eventTabTokens.filter(Boolean).length > 1
    },
    eventTabTokens() {
      return this.shortEvents.flatMap(event => event.events.map(e => e.details?.browser_tab_token)).filter((value, index, self) => self.indexOf(value) === index)
    },
  },
  methods: {
    updateDefaultTimeWindow() {
      this.timeWindow = [this.minTimestampDefault, this.maxTimestampDefault]
    },
    moveToLeft() {
      const newMinTimestamp = Math.max(this.minTimestamp - this.moveStep, this.minTimestampDefault)
      this.timeWindow = [newMinTimestamp, newMinTimestamp + this.timeWindowDuration]
    },
    moveToRight() {
      const newMaxTimestamp = Math.min(this.maxTimestamp + this.moveStep, this.maxTimestampDefault)
      this.timeWindow = [newMaxTimestamp - this.timeWindowDuration, newMaxTimestamp]
    },
    // Return events by group with same content to highlight copy/paste matches
    similarTimeEventsByGroup(event) {
      return this.shortEvents.filter(x=> x.kind !== "answers_last_update").map(shortEventGroup => {
        return {
          ...shortEventGroup,
          events: shortEventGroup.events.filter(shortEvent => {
            if (event === shortEvent) return false
            if (event.sanitized_content_for_similarity_check === null) return false

            return shortEvent.sanitized_content_for_similarity_check === event.sanitized_content_for_similarity_check
          }),
        }
      }).filter(group => group.events.length > 0)
    },
    tabIdFor(tabToken) {
      return this.eventTabTokens.indexOf(tabToken) + 1
    },
    openScreenshotDialog(screenshot) {
      this.selectedScreenshot = screenshot
      this.showScreenshotDialog = true
    },
  },
  watch: {
    longEvents: {
      immediate: true,
      handler() {
        this.updateDefaultTimeWindow()
      },
    },
    shortEvents: {
      immediateDefault: true,
      handler() {
        this.updateDefaultTimeWindow()
      },
    },
    lineEvents: {
      immediate: true,
      handler() {
        this.updateDefaultTimeWindow()
      },
    },
  },
}
</script>

<style scoped>
.timeline {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 50px;
}

.eventGroup {
  position: relative;
/* keep margin to 24 and height to 0 to allow to to hover div behind an event group line the line overlay */
  height: 0;
  margin-bottom: 24px;
  width: 100%;
}

.shortEventGroup {
  position: relative;
  height: 0;
  margin-bottom: 24px;
}

.short-event-marker, .rectangle {
  position: absolute;
  height: 20px;
  overflow: hidden;
}

.short-event-marker-background {
  width: 100%;
  height: 100%;
      font-size: 10px;
      color: white;
    font-weight: 700;
    display: flex;
    align-content: center;
    justify-content: center;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.5)
}

.rectangle-background {
  width: 100%;
  height: 100%;
}

.rectangle {
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
}

.dot{
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid white;
  transform: translate(-10px);
  transition: background-color 0.2s ease-in-out;
  margin-top: 2px;
}

.short-event-marker-line {
  width: 16px;
  transform: translate(-7px);
  padding-left: 7px;
  padding-right: 7px;
  background: transparent;
}
.short-event-marker-line:hover {
  transform: translate(-7px, -1px) scale(1.2);
  height: 300px; /* dirty hack to extend the line to the top and bottom of the timeline */

}
.short-event-marker-line > * {
  border-radius: 2px;
}

.big-short-event-marker {
  width: 10px;
  height: 10px;
  margin-top: 5px;
  margin-left: 3px;
}

.dot:hover {
  transform: translate(-10px, -1px) scale(1.2);
}

.line {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 1px;
  border-left: 1px solid #00000008;
}

.hover-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  background-color: transparent;
  transition: background-color 0.2s ease-in-out;
  opacity: 0.2
}

.line:hover + .hover-overlay, .hover-overlay:hover {
  opacity: 0.7;
}

.event-icon{
  height: 28px;
  align-items: center;
  display: flex;
  align-content: center;
  justify-content: center;
}

.timeline-container{
  width: 100%;
}

.move-button {
  position: absolute;
  top: calc(50% - 14px);
  z-index: 2;
  min-width: 30px !important;
}

.smooth-move{
  transition: 0.2s ease-in-out;
}
#screenshots-container{
  height: 30px;
}

.screenshot-thumbnail{
}

.screenshot-marker{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 40px;
  position: absolute;
}

</style>
