<template>
  <div>
    <canvas
      ref="chart"
      class="chart"
      @click="drawChart"
    />
    <div class="caption">
      <evalmee-chip
        color="red"
        dark
        label
        small
      >
        Beta
      </evalmee-chip>
      {{ $t("live.studentEvents.kind.resizeBrowser.chart.disclaimer") }}
    </div>
  </div>
</template>

<script>
import EvalmeeChip from "@/components/shared/evalmee_chip.vue"


export default {
  name: "EventScreenSizeChart",
  components: { EvalmeeChip },
  props: {
    screenWidth: {
      type: Number,
      required: true,
    },
    screenHeight: {
      type: Number,
      required: true,
    },
    browserWidth: {
      type: Number,
      required: true,
    },
    browserHeight: {
      type: Number,
      required: true,
    },
    browserPositionX: {
      type: Number,
      required: true,
    },
    browserPositionY: {
      type: Number,
      required: true,
    },
  },
  mounted() {
    this.drawChart()
    // this.drawBackground()
  },
  computed: {
    actualScreenWidth() {
      const maxMultiScreenWidth =  Math.abs(this.browserPositionX) + this.browserWidth
      return Math.max(this.screenWidth, maxMultiScreenWidth)
    },
    actualScreenHeight() {
      const maxMultiScreenHeight =  Math.abs(this.browserPositionY) + this.browserHeight
      return Math.max(this.screenHeight, maxMultiScreenHeight)
    },
  },
  methods: {
    scale({ fit } = { fit: true }) {
      const canvas = this.$refs.chart
      const scaleX = canvas.width / this.actualScreenWidth
      const scaleY = canvas.height / this.actualScreenHeight
      return fit ? Math.min(scaleX, scaleY) : Math.max(scaleX, scaleY)
    },

    drawChart() {
      // Draw two rectangles: one for the screen and one for the browser
      // This char should be zoomed to fit the canvas size. x and y scale should be the same
      const browserColor = this.$vuetify.theme.currentTheme.primary

      const canvas = this.$refs.chart
      const ctx = canvas.getContext("2d")
      ctx.clearRect(0, 0, canvas.width, canvas.height)

      const screenW = this.actualScreenWidth
      const screenH = this.actualScreenHeight

      const browserX = Math.abs(this.browserPositionX)
      const browserY = Math.abs(this.browserPositionY)
      const browserW = this.browserWidth
      const browserH = this.browserHeight
      const screenBorders = 10


      const scale = this.scale()

      const drawRect = ({ x, y, w, h, color, alpha, text, border, rounded } = { alpha: 1, border: false, rounded: false }) => {
        const cornerRadius = rounded ? 5 : 0

        ctx.save()
        ctx.globalAlpha = alpha
        ctx.fillStyle = color
        const region = new Path2D()
        region.roundRect(x, y, w, h, cornerRadius)
        ctx.fill(region)
        ctx.clip(region, "evenodd")
        ctx.restore()


        if (border) {
          const borderWidth = 1
          const borderRegion = new Path2D()
          // important: the border should be INSIDE the rectangle area
          borderRegion.roundRect(x + borderWidth, y + borderWidth, w - 2 * borderWidth, h - 2 * borderWidth, cornerRadius)
          ctx.strokeStyle = color
          ctx.stroke(borderRegion)
        }

        // text in the middle of the rectangle
        if (text) {
          ctx.fillStyle = "black"
          ctx.font = "12px Arial"
          ctx.textAlign = "center"
          ctx.fillText(text, x  + w  / 2, y  + h  / 2)
        }

        return ctx
      }

      const drawBrowserWindow = ({ x, y, w, h, color, text }) => {
        const topBarHeight = h * 0.1

        drawRect({
          x: x,
          y: y,
          w: w,
          h: h,
          color: color,
          alpha: 0.1,
          text: text,
          border: true,
        })

        // draw top bar
        drawRect({
          x: x,
          y: y,
          w: w,
          h: topBarHeight,
          color: color,
          alpha: 1,
        })
      }


      const drawScreen = ({ x, y, w, h, color }) => {
        // draw screen : a rectangle with inside, a smaller centered rectangle with a padding of 10px.

        const innerW = w - 2 * screenBorders
        const innerH = h - 2 * screenBorders

        drawRect({
          x: x,
          y: y,
          w: w,
          h: h,
          color: "#727272",
          alpha: 0.5,
          text: this.$t("live.studentEvents.kind.resizeBrowser.chart.screen"),
          border: true,
          rounded: true,
        })
        // ctx.save()
        // ctx.rect(
        //   x + padding / 2,
        //   y + padding / 2,
        //   w - padding,
        //   h - padding
        // )
        // ctx.lineWidth = padding
        // ctx.strokeStyle = color
        // ctx.stroke()
        // ctx.restore()

        drawRect({
          x: x + screenBorders,
          y: y + screenBorders,
          w: innerW,
          h: innerH,
          color: this.$vuetify.theme.currentTheme.background,
          alpha: 1,
        })
      }

      drawScreen({
        x: 0,
        y: 0,
        w: screenW * scale,
        h: screenH * scale,
        color: "#6d6d6d",
      })

      drawBrowserWindow({
        x: browserX * scale + screenBorders,
        y: browserY * scale + screenBorders,
        w: browserW * scale - 2 * screenBorders,
        h: browserH * scale - 2 * screenBorders,
        color: browserColor,
        text: this.$t("live.studentEvents.kind.resizeBrowser.chart.browser"),
      })
    },
  },
}
</script>


<style scoped>
.chart {
  width: auto;
  height: 100%;
  max-width: 100%;
}

</style>

