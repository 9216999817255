<template>
  <v-alert
    v-if="score && score.answers_to_review_count > 0"
    type="warning"
    :icon="false"
    text
  >
    <v-row
      align="center"
      class="mb-0"
    >
      <v-col class="grow">
        <span v-html="this.$tc('live.correction.afterClosing.content', score.answers_to_review_count, {answers: score.answers_to_review_count, date: expectedEndDateSentence})" />
      </v-col>
      <v-col class="shrink">
        <v-btn
          color="orange"
          depressed
          @click="acceptAnswers"
        >
          <v-icon
            left
            v-html="'mdi-thumb-up-outline'"
          />
          {{ $t('live.correction.afterClosing.buttons.acceptAll') }}
        </v-btn>
      </v-col>
      <v-col class="shrink">
        <v-btn
          color="orange"
          text
          @click="rejectAnswers"
        >
          <v-icon
            left
            v-html="'mdi-thumb-down-outline'"
          />
          {{ $t('live.correction.afterClosing.buttons.refuseAll') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-alert>
</template>

<script>
import { mapActions } from "vuex"
import questionStateMixin from "./question_state_mixin"
import instanceScoreDuration from "../quiz/evalmee/instance_score_duration"

export default {
  name: "OutOfTimeSeveralAlert",
  mixins: [questionStateMixin, instanceScoreDuration],
  props: {
    score: { type: Object, required: true },
    answers: { type: Array, required: true },
    quizInstance: {
      type: Object,
      default: null,
    },
    quiz: {
      type: Object,
      required: true,
    },
  },
  methods: {
    ...mapActions(["handleEventAnswer"]),
    acceptAnswers() {
      this.answersToReview.forEach(a =>
        this.handleEventAnswer({ answer: a, status: "accepted" })
      )
    },
    rejectAnswers(){
      this.answersToReview.forEach(a =>
        this.handleEventAnswer({ answer: a, status: "rejected" })
      )
    },
  },
  computed: {
    answersToReview() {
      return this.answers.filter(a => {
        return ["out_of_time", "timer_timeout"].includes(a.status)
      })
    },
    expectedEndDate() {
      return this.score.finished_at
    },
    expectedEndDateSentence() {
      if(!this.quizInstance) return

      return this.$moment(this.expectedEndDate)
        .format((`LTS [${this.$t("live.correction.outOfTime.of")}] L`))
    },
  },
}
</script>
