<template>
  <div>
    <div
      class="ma-2 diff-container"
    >
      <div
        class="scrollable pb-4"
        ref="scrollable"
        v-intersect="onIntersect"
      >
        <div>
          <span
            v-for="(part, index) in diff"
            :key="index"
          >
            <span
              v-if="part.added"
              class="green--text green lighten-5"
              ref="added"
            >
              {{ part.value }}
            </span>
            <span
              v-if="part.removed"
              class="red--text red lighten-5"
            >
              {{ part.value }}
            </span>
            <span v-if="!part.added && !part.removed">
              {{ part.value }}
            </span>
          </span>
        </div>
      </div>
    </div>
    <v-divider />
    <div class="px-2 py-1 grey lighten-3 caption">
      {{ $t("live.studentEvents.kind.bulkInsert.diffTool.text") }}
      <span class="green--text green lighten-5">
        {{ $t("live.studentEvents.kind.bulkInsert.diffTool.added") }}
      </span>
      <span class="red--text red lighten-5">
        {{ $t("live.studentEvents.kind.bulkInsert.diffTool.removed") }}
      </span>
    </div>
  </div>
</template>
<script>

import { useScroll } from "@/composables/useScroll"

export default {
  name: "EventTextDiff",
  props: {
    diff : {
      // The `diff` prop is an array that represents the differences between two text strings.
      // Each element in the array is an object with the following properties:
      // - `value`: The text that was added, removed, or unchanged.
      // - `added`: A boolean that is true if the text was added.
      // - `removed`: A boolean that is true if the text was removed.
      // This prop is required for the component to function correctly.
      type: Array,
      required: true,
    },
  },
  setup() {
    return {
      useScroll,
    }
  },
  data: () => ({
    isVisible: false,
  }),
  methods: {
    scrollToFirstAdded() {
      const added = this.$refs.added && this.$refs.added[0]
      this.useScroll().to(added, this.$refs.scrollable)
    },
    onIntersect(entries) {
      // The callback function is called whenever the observed element enters or exits the viewport.
      this.isVisible = false
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.isVisible = true
        }
      })
    },
  },
  watch: {
    isVisible: {
      handler(val) {
        if (val) this.scrollToFirstAdded()
      },
      immediate: true,
    },
  },
}
</script>

<style scoped>
.scrollable {
  max-height: 200px;
  overflow: scroll;
}

 .diff-container {
    overflow: hidden;
   max-height: 200px;
   position: relative;
 }
 .diff-container::before{
   content: "";
   display: block;
   height: 25%;
   width: 100%;
   position: absolute;
   bottom: 0;
   left: 0;
   background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,0.9) 100%);
   pointer-events: none;
 }
</style>
